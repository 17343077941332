import React, {useState, useEffect} from 'react';
import {MdLocationOn, MdEmail} from "react-icons/md";
import {BsFillTelephoneFill,BsFillClockFill} from "react-icons/bs";
import Footer from '../Footer/Footer';
import './contactStyle.css';
import emailjs from '@emailjs/browser';
import ScrollToTop from '../../ScrollToTop';
import Animation from '../../Animation';
import {AnimatedOnScroll} from "react-animated-css-onscroll";
import BackToTop from '../BackToTop/BackToTop';


const EJ_U = `${process.env.REACT_APP_EMJS}`;

function Contact(props){
    const [yourName, setYourName] = useState("");
    const [nameValid, setNameValid] = useState(false);
    const [yourPhone, setYourPhone] = useState("");
    const [phoneValid, setPhoneValid] = useState(false);
    const [yourEmail, setYourEmail] = useState("");
    const [emailValid, setEmailValid] = useState(false);
    const [yourIssues, setYourIssues] = useState("");
    const [yourMessage, setYourMessage] = useState("");
    const [robotCheck, setRobotCheck] = useState(false);
    const [sendingMessage, setSendingMessage] = useState(false);
    const [messageSent, setMessageSent] = useState(false);
    const [reqFieldsFilled, setReqFieldsFilled] = useState(false);
    const [formErrors, setFormErrors] = useState("");

    const defaultProps = {
        center: {
          lat: 10.99835602,
          lng: 77.01502627
        },
        zoom: 11
      };

    useEffect(()=>{

        //Name check
        let namePattern = /^[A-Za-z? , _-]+$/;
        if(namePattern.test(yourName)){
            setNameValid(true);
        } else if(!namePattern.test(yourName)) {
            setNameValid(false);
        }

        //Email pattern check
        let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
        if(emailPattern.test(yourEmail)){
            setEmailValid(true);
        } else if(!emailPattern.test(yourEmail)) {
            setEmailValid(false);
        }

        //Phone number check
        let phonePattern = /^[0-9? , _-]+$/;
        if(phonePattern.test(yourPhone)){
            setPhoneValid(true);
        } else if(!phonePattern.test(yourPhone)) {
            setPhoneValid(false);
        }

        let robotCheckValid = robotCheck && (props.navClickCountProp > 1);

        if( nameValid && 
            phoneValid  && 
            emailValid  && 
            yourMessage !== "" &&
            robotCheckValid){

            setReqFieldsFilled(true);

        } else {
            setReqFieldsFilled(false);
        }

        //set form errors
        let formErrorTitles = [];
        let formErrorFinalString = "";
        if(!nameValid){
            formErrorTitles.push("Name invalid, please enter only letters")
        }

        if(!phoneValid){
            formErrorTitles.push("Phone number invalid, please dont enter letters")
        }

        if(!emailValid){
            formErrorTitles.push("Please enter a valid email address")
        }

        setFormErrors(formErrorTitles.join("\n\r"));        

    },[yourName,yourPhone,yourEmail,yourMessage, emailValid, phoneValid, nameValid, robotCheck])


    function handleRobotCheck(){
        setRobotCheck(!robotCheck);

    }



    function handleSubmit(event){
        event.preventDefault();
        setSendingMessage(true)
        sendEmail(); // send email
        
        setYourName("");
        setYourPhone("");
        setYourEmail("");
        setYourIssues("");
        setYourMessage("");
    }

    const sendEmail = () => {

        let templateParams = {
            to_name: yourName,
            to_phone: yourPhone,
            from_email: yourEmail,
            issues: yourIssues,
            message: yourMessage,
        }

        var service_id = "service_o00p5l7";
		var template_id = "template_wgi0gnc";

		var user_id = EJ_U;
    
        emailjs.send(service_id, template_id, templateParams, user_id)
          .then((result) => {

              setTimeout(()=>{
                setSendingMessage(false);
              },3000)
              

            setMessageSent(true);

          }, (error) => {
              console.log(error.text, "from error ");
          });
      };

    return(
        <Animation>
        <div> 
            <ScrollToTop />
            <div className="contactContainer">
                <div className="contactFormContainer">
                    <div className="contactFormHeader">
                        <p>CONTACT</p>
                        <p>Get In <span className="titleOffWord">Touch</span></p>
                        <p>Please send us a message and we will get back to you</p>
                    </div>
                    
                     
                    {sendingMessage ? (

        
                        <div className="loaderContainer">
                            <div className="loader"></div>
                            <h1>Sending Message</h1>
                        </div>
                    ) :
                        
                        messageSent ? (
                        <div className="loaderContainer">
                            <h1>Your message has been sent!</h1>
                            <p>We will contact you as soon as possible.</p>
                        </div>

                        ) : (
                        <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOut">
                        <form onSubmit={e => {handleSubmit(e)}} className='contactInputContainer'>
                        <div className="contactName">
                            <input 
                                placeholder="Your Name (required)" 
                                type="text" 
                                name="name"
                                value={yourName}
                                onChange={e => setYourName(e.target.value)}
                                required
                            />
                           
                        </div>
                        <div className="contactPhoneEmail">
                            <input 
                                placeholder="Phone Number (required)" 
                                type="text" 
                                name="phone"
                                value={yourPhone}
                                onChange={e => setYourPhone(e.target.value)}
                                required
                            />
                            <input 
                                placeholder="Email (required)" 
                                type="email" 
                                name="email"
                                value={yourEmail}
                                onChange={e => setYourEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="contactQuestions">
                            <input 
                                placeholder="When did you notice the issue?" 
                                type="text" 
                                name="issues"
                                value={yourIssues}
                                onChange={e => setYourIssues(e.target.value)}
                            />
                        </div>
                        <div className="contactTextAreaDiv">
                            <textarea
                                placeholder='Please explain what is going on'
                                name="message"
                                value={yourMessage}
                                onChange={e => setYourMessage(e.target.value)}
                            />
                        </div>

                        <div className='contactRobotCheckContainer'>
                            <p>I'm not a robot</p>
                            <input type="checkbox"  onChange={handleRobotCheck} />
                        </div>
                    
                        <input 
                            type="submit" 
                            value="Submit" 
                            className={!reqFieldsFilled ? "contactSubmitButton disabledSubmit" : "contactSubmitButton"}
                            disabled={!reqFieldsFilled}
                            title={formErrors}
                        />
                    </form>
                    </AnimatedOnScroll>
                    )}

                    
                    
                </div>
                <AnimatedOnScroll animationIn="fadeInRight" animationOut="fadeOut">
                <div className="contactBottomSquaresContainer">
                    {/* <div className="contactSquares square1">
                        <div className="contactSquareIcon">
                            <MdLocationOn />
                        </div>
                        <div className="contactSquareText">
                            <p>Address</p>
                            <p>PO BOX ****</p>
                        </div>
                    </div>  */}
                    <div className="contactSquares square2">
                            <a href="tel:7729856561" className='contactAnchorContact'>
                                <div className="contactSquareIcon">
                                    <BsFillTelephoneFill />
                                </div>
                            </a>

                            <a href="tel:7729856561" className='contactAnchorContact'>
                                <div className="contactSquareText">
                                    <p>Call</p>
                                    <p>1-772-985-6561</p>
                                </div>
                            </a>
                        
                    </div>
                    
                    <div className="contactSquares square3">
                        <a href="mailto:azrodentproofing@gmail.com?" className='contactAnchorContact'>
                            <div className="contactSquareIcon">
                                    <MdEmail />
                            </div>
                        </a>
                        <div className="contactSquareText">
                            <a href="mailto:azrodentproofing@gmail.com?" className='contactAnchorContact'>
                                <p>Email</p>
                                <p>azrodentproofing@gmail.com</p>
                            </a>
                        </div>
                    </div>
                    {/* 
                    <div className="contactSquares square1">
                        <div className="contactSquareIcon">
                            <MdLocationOn />
                        </div>
                        <div className="contactSquareText">
                            <p>Address</p>
                            <p>PO BOX 972 Laveen AZ 85339</p>
                        </div>
                    </div> 
                    <div className="contactSquares square2">
                        <div className="contactSquareIcon">
                            <BsFillTelephoneFill />
                        </div>
                        <div className="contactSquareText">
                            <p>Call</p>
                            <p>1-480-493-5742</p>
                        </div>
                    </div>
                    <div className="contactSquares square3">
                        <div className="contactSquareIcon">
                            <MdEmail />
                        </div>
                        <div className="contactSquareText">
                            <p>Email</p>
                            <p>azrodentproofing@gmail.com</p>
                        </div>
                    </div>
                    */}

                    {/* <div className="contactSquares square4">
                        <div className="contactSquareIcon">
                            <BsFillClockFill />
                        </div>
                        <div className="contactSquareText">
                            <p>Hours</p>
                            <p>M-F 8-3pm</p>
                        </div>
                    </div> */}
                </div>
                </AnimatedOnScroll> 
            </div>

            <div className="contact-map-container" >
                <AnimatedOnScroll animationIn="fadeInLeft" animationOut="fadeOut">
                <div className="contact-map">
                    <p>SERVICE AREAS: </p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1003596.2787987061!2d-112.24746790049505!3d34.28515584167197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b08ebcb4c186b%3A0x423927b17fc1cd71!2sArizona!5e0!3m2!1sen!2sus!4v1676788255229!5m2!1sen!2sus" 
                    allowfullscreen={"false"} loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                    className='contact-map-iframe'></iframe>
                </div>
                </AnimatedOnScroll>
            </div>
            
            <BackToTop />
            <Footer />
        </div>
        </Animation>
    )
}

export default Contact;