import React,{useState, useEffect} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import TopInfo from './components/TopInfo/TopInfo';
import Home from './components/Home/Home';
import Services from './components/Services/Services';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Accessibility from './components/Accessibility/Accessibility';
import Terms from './components/Terms/Terms';
import {AnimatePresence} from 'framer-motion';
import { Element } from 'react-scroll';

function App() {
  const [navClickCount, setNavClickCount] = useState();
  function childNavClickCount(cCount){
    setNavClickCount(cCount)
  }
  
  return (
      // <BrowserRouter>
      //   <Element name="topOfPage" className="element" />
      //   <TopInfo />

      //   <Navbar getClickCount={childNavClickCount} />
      //   <AnimatePresence exitBeforeEnter>
      //   <Routes>
      //     <Route exact path='/' element={<Home />} />
      //     <Route exact path='/home' element={<Home />} /> 
      //     <Route exact path='/services' element={<Services />} />
      //     <Route exact path='/about' element={<About />}/>
      //     <Route exact path='/contact' element={<Contact navClickCountProp={navClickCount} />} />

      //     <Route exact path='/accessibility' element={<Accessibility />} />
      //     <Route exact path='/terms' element={<Terms />} />
      //   </Routes>
      //   </AnimatePresence>
      // </BrowserRouter>
        <div className="errorPage">
        <h1>404</h1>
        <h3>Page not found</h3>
        <p>The link you clicked may be broken or the page may 
          have been removed.
        </p>
        {/* when want to activate, also fix index.html*/}
      </div>
  );
}

export default App;
